import Vue from "vue";
import Vuex from "vuex";
import { State } from "@/store/state";
import { User, fetchIdToken } from "@/models/user";
import { setAuthHeader } from "@/services/api/axios";
import { BeforeInstallPromptEvent } from "vue-pwa-install";
import { isAndroid, isInStandaloneMode, isIos } from "@/plugins/utils";
import { ExpiringValue } from "@/value-objects/expiring-value";
import { addAnalyticsEvent } from "@/plugins/firebase";
import { ROUTE_NAMES } from "@/router";
import { getRudderstack } from "@/plugins/rudderstack";

Vue.use(Vuex);

const LOCAL_STORAGE_KEYS = {
    REFERRAL_CODE: "REFERRAL_CODE",
    NAV_ROUTE: "NAV_PATH",
};

function getExpiringValueFromLocalStorage(
    key: string
): ExpiringValue<string> | null {
    const value = localStorage.getItem(key)
        ? (JSON.parse(
              localStorage.getItem(key) ?? "null"
          ) as ExpiringValue<string> | null)
        : null;
    if (!value) {
        return null;
    }

    value.expiresAt = new Date(value.expiresAt);
    if (value.expiresAt.getTime() < new Date().getTime()) {
        localStorage.removeItem(key);
        return null;
    }

    return value;
}

export default new Vuex.Store({
    state: {
        authToken: localStorage.getItem("authToken")
            ? JSON.parse(localStorage.getItem("authToken") ?? "null")
            : null,
        user: localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user") ?? "null")
            : null,
        deferredInstallPromptEvent: null,
        loading: true,
        previousPath: null,
        navRoute: localStorage.getItem(LOCAL_STORAGE_KEYS.NAV_ROUTE)
            ? JSON.parse(
                  localStorage.getItem(LOCAL_STORAGE_KEYS.NAV_ROUTE) ?? "null"
              )
            : null,
        referralCode: getExpiringValueFromLocalStorage(
            LOCAL_STORAGE_KEYS.REFERRAL_CODE
        ),
    } as State,
    mutations: {
        setUser(state: State, user: User | null) {
            state.user = user;
        },

        setLoading(state: State, loading: boolean) {
            state.loading = loading;
        },

        setPreviousPath(state: State, path: string) {
            state.previousPath = path;
        },

        setNavRoute(state: State, route: string) {
            state.navRoute = route;
            localStorage.setItem(
                LOCAL_STORAGE_KEYS.NAV_ROUTE,
                JSON.stringify(route)
            );
        },

        setAuthToken(state: State, authToken: string | null) {
            state.authToken = authToken;
        },

        setReferralCode(
            state: State,
            referralCode: ExpiringValue<string> | null
        ) {
            state.referralCode = referralCode;
        },

        setDeferredInstallPromptEvent(
            state: State,
            deferredInstallPromptEvent: BeforeInstallPromptEvent
        ) {
            state.deferredInstallPromptEvent = deferredInstallPromptEvent;
        },
    },
    actions: {
        setUser({ commit }, user: User | null) {
            addAnalyticsEvent("setting_user", { isNull: user === null });
            let serializedUser = user;
            if (user != null) {
                const {
                    displayName,
                    email,
                    phoneNumber,
                    photoURL,
                    providerId,
                    uid,
                } = user;
                serializedUser = {
                    displayName,
                    email,
                    photoURL,
                    phoneNumber,
                    providerId,
                    uid,
                };
            }
            localStorage.setItem("user", JSON.stringify(serializedUser));
            commit("setUser", serializedUser);

            if (user) {
                getRudderstack().identify(user?.uid, {
                    name: user?.displayName as string,
                    email: user?.email as string,
                });
            }

            fetchIdToken(user).then((token: string | null) => {
                localStorage.setItem("authToken", JSON.stringify(token));
                setAuthHeader(token);
                commit("setAuthToken", token);
            });
        },

        setLoading({ commit }, loading: boolean) {
            commit("setLoading", loading);
        },

        setPreviousPath({ commit }, path: string) {
            commit("setPreviousPath", path);
        },

        setNavRoute({ commit }, route: string) {
            commit("setNavRoute", route);
        },

        setAuthToken({ commit }, authToken: string | null) {
            localStorage.setItem("authToken", JSON.stringify(authToken));
            setAuthHeader(authToken);
            commit("setAuthToken", authToken);
        },

        setReferralCode({ commit }, referralCode: string | null) {
            if (!referralCode) {
                localStorage.removeItem(LOCAL_STORAGE_KEYS.REFERRAL_CODE);
                commit("setReferralCode", null);
            }

            const expiresAt = new Date();
            expiresAt.setDate(expiresAt.getDate() + 30);

            const code = {
                value: referralCode?.trim(),
                expiresAt: expiresAt,
            } as ExpiringValue<string>;

            localStorage.setItem(
                LOCAL_STORAGE_KEYS.REFERRAL_CODE,
                JSON.stringify(code)
            );
            commit("setReferralCode", code);
        },

        setDeferredInstallPromptEvent(
            { commit },
            deferredInstallPromptEvent: BeforeInstallPromptEvent
        ) {
            commit("setDeferredInstallPromptEvent", deferredInstallPromptEvent);
        },
    },
    getters: {
        user(state: State): User | null {
            if (state.user === null || state.user.email === null) {
                return null;
            }
            return state.user;
        },

        loading(state: State): boolean {
            return state.loading;
        },

        userIsAnonymous(state: State): boolean {
            return state.user !== null && state.user.email === null;
        },

        isLoggedIn(state: State): boolean {
            return state.user !== null && state.user.email !== null;
        },

        navRoute(state: State): string | null {
            if (state.navRoute === null) {
                return null;
            }

            if (
                Object.keys(ROUTE_NAMES).find(
                    (key) =>
                        (ROUTE_NAMES as Record<string, string>)[key] ===
                        state.navRoute
                )
            ) {
                return state.navRoute;
            }

            addAnalyticsEvent("error_invalid_nav_route", {
                route: state.navRoute,
            });

            return null;
        },

        previousPath(state: State): string | null {
            return state.previousPath;
        },
        hasReferralCode(state: State): boolean {
            return (
                state.referralCode !== null &&
                state.referralCode?.value.trim() !== ""
            );
        },

        referralCode(state: State): string {
            return state.referralCode?.value ?? "";
        },

        userIsAdmin(state: State): boolean {
            const user = state.user;
            if (!user) {
                return false;
            }
            return (
                [
                    "XK5sTR7CRiWlgfAHJi7sQetV5zF3",
                    "PumEx4QZ2XdRhCWeaktNaHltV2G3",
                ].indexOf(user.uid) !== -1
            );
        },

        userIsCustomerServiceAgent(state: State): boolean {
            const user = state.user;
            if (!user) {
                return false;
            }
            return (
                [
                    "gMvl8FztmhhqNwFYlAlCmj6kuy13",
                    "7sCUmIVKVKVNnHf3HafwSAU34i72",
                ].indexOf(user.uid) !== -1
            );
        },

        userIsAdminOrCustomerServiceAgent(state: State): boolean {
            const user = state.user;
            if (!user) {
                return false;
            }
            return (
                [
                    // admins
                    "XK5sTR7CRiWlgfAHJi7sQetV5zF3",
                    "PumEx4QZ2XdRhCWeaktNaHltV2G3",
                    // customer service agents
                    "gMvl8FztmhhqNwFYlAlCmj6kuy13",
                    "7sCUmIVKVKVNnHf3HafwSAU34i72",
                ].indexOf(user.uid) !== -1
            );
        },

        userIsArnold(state: State): boolean {
            return state.user?.uid === "PumEx4QZ2XdRhCWeaktNaHltV2G3";
        },

        authToken(state: State): string | null {
            return state.authToken;
        },

        canInstallApp(): boolean {
            return (
                (!isInStandaloneMode() && isAndroid()) ||
                (!isInStandaloneMode() && isIos())
            );
        },
        deferredInstallPromptEvent(
            state: State
        ): BeforeInstallPromptEvent | null {
            return state.deferredInstallPromptEvent;
        },
    },
});
