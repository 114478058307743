





























































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { siTiktok, siX, siFacebook, siInstagram } from "simple-icons";
import {
    mdiCog,
    mdiLogin,
    mdiLogout,
    mdiHistory,
    mdiAccountCog,
    mdiFormatListBulleted,
    mdiListStatus,
    mdiForum,
    mdiForumOutline,
    mdiViewDashboard,
    mdiPipeWrench,
    mdiAccountMultiple,
    mdiCellphoneBasic,
    mdiCashMultiple,
    mdiWallet,
    mdiTelevisionClassic,
    mdiLightbulbOnOutline,
    mdiWaterPump,
    mdiBitcoin,
    mdiSwapHorizontal,
    mdiTransfer,
    mdiTelevisionShimmer,
} from "@mdi/js";
import NotificationMessage from "@/components/NotificationMessage.vue";
import Logo from "@/components/Logo.vue";
import { Location } from "vue-router";
import firebase from "@/plugins/firebase";
import { Action, Getter } from "vuex-class";
import { ROUTE_NAMES } from "@/router";
import InstallButton from "@/components/InstallButton.vue";
import { BeforeInstallPromptEvent } from "vue-pwa-install";
import { addAnalyticsEvent } from "@/plugins/firebase";
import SocialButtons from "@/components/SocialButtons.vue";
import { captureSentryException } from "@/plugins/sentry";
import { User } from "@/models/user";
import PushNotificationsButton from "@/components/PushNotificationsButton.vue";
import StatusAlert from "@/components/StatusAlert.vue";
import ReferralApi from "@/services/api/referral";
import VersionApi from "@/services/api/version";
import { appVersion, isWebView } from "@/plugins/utils";
import { AxiosResponse } from "axios";
import UpdateAppButton from "@/components/UpdateAppButton.vue";
import { envIsLocal } from "./plugins/env";
import { isDarkModeOn } from "@/plugins/vuetify";

interface MenuItem {
    name: string;
    icon?: string;
    visible: boolean;
    routeNames: Array<string>;
    route: Location;
}

@Component({
    methods: { isDarkModeOn },
    components: {
        UpdateAppButton,
        StatusAlert,
        PushNotificationsButton,
        SocialButtons,
        InstallButton,
        NotificationMessage,
        Logo,
    },
})
export default class App extends Vue {
    @Getter("user") user!: User | null;
    @Getter("loading") loading!: boolean;
    @Getter("userIsAnonymous") userIsAnonymous!: boolean;
    @Getter("authToken") authToken!: string | null;
    @Getter("userIsAdmin") userIsAdmin!: boolean;
    @Getter("userIsCustomerServiceAgent") userIsCustomerServiceAgent!: boolean;
    @Getter("userIsArnold") userIsArnold!: boolean;
    @Action("setUser") setUser!: (user: User | null) => void;
    @Action("setAuthToken") setAuthToken!: (token: string | null) => void;
    @Getter("canInstallApp") canInstallApp!: boolean;
    @Action("setReferralCode") setReferralCode!: (code: string | null) => void;
    @Action("setDeferredInstallPromptEvent") setDeferredInstallPromptEvent!: (
        setDeferredInstallPromptEvent: BeforeInstallPromptEvent | null
    ) => void;
    @Action("setNavRoute") setNavRoute!: (route: string | null) => void;

    drawerOpen: boolean = false;
    electricityIcon: string = mdiLightbulbOnOutline;
    waterIcon: string = mdiWaterPump;
    cryptoIcon: string = mdiBitcoin;
    canalplusIcon: string = mdiTelevisionShimmer;
    swapIcon: string = mdiTransfer;
    exchangeIcon: string = mdiSwapHorizontal;
    payIcon: string = mdiTelevisionClassic;
    phoneIcon: string = mdiCellphoneBasic;
    signInIcon: string = mdiLogin;
    logoutIcon: string = mdiLogout;
    adminIcon: string = mdiAccountCog;
    settingsIcon: string = mdiCog;
    allOrdersIcon: string = mdiFormatListBulleted;
    allEventsIcon: string = mdiListStatus;
    twitterIcon = siX;
    tiktokIcon = siTiktok;
    facebookIcon = siFacebook;
    instagramIcon = siInstagram;
    crmIcon: string = mdiAccountMultiple;
    isLoggingOut: boolean = false;
    version: string = process.env.VUE_APP_COMMIT_HASH.substr(0, 7);
    messagesIcon: string = mdiForumOutline;
    dashboardIcon: string = mdiViewDashboard;
    showUpdateButton = false;

    kitchenSinkItems: Array<MenuItem> = [
        {
            visible: this.userIsArnold,
            name: "Kitchen Sink",
            icon: mdiPipeWrench,
            route: {
                name: this.$constants.ROUTE_NAMES.KITCHEN_SINK_LOGS,
            },
            routeNames: [this.$constants.ROUTE_NAMES.KITCHEN_SINK_LOGS],
        },
    ];

    secondaryMenuItems: Array<MenuItem> = [
        {
            visible: true,
            name: "Contact Us",
            route: {
                name: this.$constants.ROUTE_NAMES.CONTACT_US_INDEX,
            },
            routeNames: [],
        },
        {
            visible: true,
            name: "Affiliates",
            route: {
                name: this.$constants.ROUTE_NAMES.AFFILIATES_INDEX,
            },
            routeNames: [],
        },
        {
            visible: true,
            name: "DStv Channels",
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_CHANNELS,
            },
            routeNames: [],
        },
        {
            visible: true,
            name: "FAQ",
            route: {
                name: this.$constants.ROUTE_NAMES.FAQ_INDEX,
            },
            routeNames: [],
        },
        {
            visible: true,
            name: "Terms",
            route: {
                name: this.$constants.ROUTE_NAMES.TERMS_AND_CONDITIONS_SHOW,
            },
            routeNames: [],
        },
        {
            visible: true,
            name: "Privacy Policy",
            route: {
                name: this.$constants.ROUTE_NAMES.PRIVACY_POLICY_SHOW,
            },
            routeNames: [],
        },
        {
            visible: true,
            name: "DStv Package",
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_EXPIRY_DATE_CHECKER,
            },
            routeNames: [],
        },
        {
            visible: true,
            name: "About Us",
            route: {
                name: this.$constants.ROUTE_NAMES.ABOUT_US,
            },
            routeNames: [],
        },
    ];

    get visibleAdminMenuItems(): Array<MenuItem> {
        const adminMenuItems = [
            {
                visible: this.userIsAdmin || this.userIsCustomerServiceAgent,
                name: "CRM",
                icon: this.crmIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES.ADMIN_CRM_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.ADMIN_CRM_INDEX],
            },
            {
                visible: this.userIsAdmin || this.userIsCustomerServiceAgent,
                name: "All Orders",
                icon: this.allOrdersIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES.ADMIN_ORDERS_INDEX,
                },
                routeNames: [
                    this.$constants.ROUTE_NAMES.ADMIN_ORDERS_INDEX,
                    this.$constants.ROUTE_NAMES.ADMIN_ORDERS_INDEX,
                ],
            },
            {
                visible: this.userIsAdmin,
                name: "All Events",
                icon: this.allEventsIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES.ADMIN_EVENTS_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.ADMIN_EVENTS_INDEX],
            },
            {
                visible: this.userIsAdmin || this.userIsCustomerServiceAgent,
                name: "All Referrers",
                icon: mdiWallet,
                route: {
                    name: this.$constants.ROUTE_NAMES.ADMIN_REFERRALS_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.ADMIN_REFERRALS_INDEX],
            },
            {
                visible: this.userIsAdmin || this.userIsCustomerServiceAgent,
                name: "Contact Messages",
                icon: this.messagesIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES
                        .ADMIN_CONTACT_MESSAGES_INDEX,
                },
                routeNames: [
                    this.$constants.ROUTE_NAMES.ADMIN_CONTACT_MESSAGES_INDEX,
                ],
            },
            {
                visible: this.userIsAdmin,
                name: "Dashboard",
                icon: this.dashboardIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES.ADMIN_DASHBOARD_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.ADMIN_DASHBOARD_INDEX],
            },
        ];
        return adminMenuItems.filter((x) => x.visible);
    }

    get primaryMenuItems(): Array<MenuItem> {
        const items = [
            {
                visible: true,
                name: "Pay DStv",
                icon: this.payIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES.DSTV_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.HOME],
            },
            {
                visible: true,
                name: "mtn-to-orange",
                icon: this.swapIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES.EXCHANGE_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.EXCHANGE_INDEX],
            },
            {
                visible: this.userIsArnold,
                name: "Sell Crypto",
                icon: this.cryptoIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES.CRYPTO_SELL,
                },
                routeNames: [this.$constants.ROUTE_NAMES.CRYPTO_SELL],
            },
            {
                visible: true,
                name: "Pay Eneo Bills",
                icon: this.electricityIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES.ENEO_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.ENEO_INDEX],
            },
            {
                visible: true,
                name: "Buy Airtime",
                icon: this.phoneIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES.AIRTIME_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.AIRTIME_INDEX],
            },
            {
                visible: true,
                name: "Pay CANAL+",
                icon: this.canalplusIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES.CANALPLUS_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.CANALPLUS_INDEX],
            },
            {
                visible: true,
                name: "Pay Camwater Bills",
                icon: this.waterIcon,
                route: {
                    name: this.$constants.ROUTE_NAMES.CAMWATER_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.CAMWATER_INDEX],
            },
            {
                visible: this.user !== null,
                name: "My Orders",
                icon: mdiHistory,
                route: { name: this.$constants.ROUTE_NAMES.ORDERS_INDEX },
                routeNames: [
                    this.$constants.ROUTE_NAMES.ORDERS_INDEX,
                    this.$constants.ROUTE_NAMES.ORDERS_SHOW,
                ],
            },
            {
                visible: true,
                name: "Contact Us",
                icon: mdiForum,
                route: {
                    name: this.$constants.ROUTE_NAMES.CONTACT_US_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.CONTACT_US_INDEX],
            },
            {
                visible: this.user !== null && !this.userIsAnonymous,
                name: "Refer & Earn 2K",
                icon: mdiCashMultiple,
                route: {
                    name: this.$constants.ROUTE_NAMES.REFERRALS_INDEX,
                },
                routeNames: [this.$constants.ROUTE_NAMES.REFERRALS_INDEX],
            },
        ];
        return items.filter((x) => x.visible);
    }

    get backgroundClass(): string {
        if (!this.$vuetify.theme.dark) {
            return "grey lighten-5";
        }
        return "";
    }

    get isLocal(): boolean {
        return envIsLocal;
    }

    get isLandingPage(): boolean {
        return (
            this.$route.name === this.$constants.ROUTE_NAMES.HOME ||
            this.$route.name === null
        );
    }

    created() {
        this.$on("beforeinstallprompt", (event: BeforeInstallPromptEvent) => {
            addAnalyticsEvent("before_install_prompt_event_captured");
            // Prevent Chrome 67 and earlier from automatically showing the prompt:
            event.preventDefault();
            this.setDeferredInstallPromptEvent(event);
        });

        if (this.$vuetify.breakpoint.lgAndUp) {
            this.drawerOpen = true;
        }
    }

    get isWebView(): boolean {
        return isWebView();
    }

    get systemBarHeight(): number {
        let webViewPadding = 0;
        if (this.isWebView) {
            webViewPadding = 24;
        }

        switch (this.$vuetify.breakpoint.name) {
            case "xs":
                return 60 + webViewPadding;
            default:
                return 80 + webViewPadding;
        }
    }
    toggleNavigationDrawer(): void {
        addAnalyticsEvent("nav_bar_drawer_toggle", {
            previousState: this.drawerOpen,
        });
        this.drawerOpen = !this.drawerOpen;
    }

    onAuthBtnClicked() {
        addAnalyticsEvent("nav_drawer_auth_btn_clicked");
    }

    listenForDarkMode() {
        const darkMediaQuery = window.matchMedia(
            "(prefers-color-scheme: dark)"
        );
        try {
            // Chrome & Firefox
            darkMediaQuery.addEventListener("change", (event) => {
                this.$vuetify.theme.dark = event.matches;
            });
        } catch (error) {
            try {
                // Safari
                darkMediaQuery.addListener(() => {
                    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
                });
            } catch (error) {
                captureSentryException(error);
            }
        }
    }

    loadReferralCode() {
        const inviteCode = new URLSearchParams(window.location.search).get(
            "invite"
        );
        if (!inviteCode) {
            return;
        }

        ReferralApi.storeClick(inviteCode.trim()).catch(captureSentryException);

        this.setReferralCode(inviteCode.trim());
        return;
    }

    checkVersion() {
        VersionApi.get()
            .then((response: AxiosResponse) => {
                if (appVersion() != response.data.trim()) {
                    this.showUpdateButton = true;
                }
            })
            .catch(captureSentryException);
    }

    registerEvents() {
        this.$root.$on(this.$constants.DRAWER_EVENTS.HIDE, () => {
            this.drawerOpen = false;
        });
    }

    mounted() {
        this.listenForDarkMode();
        this.loadReferralCode();
        this.checkVersion();

        this.registerEvents();

        firebase.auth().onIdTokenChanged((user) => {
            this.setUser(user);
        });

        setTimeout(() => {
            addAnalyticsEvent("application_loaded");
        }, 5000);

        if (!firebase.messaging.isSupported()) {
            addAnalyticsEvent("messaging_is_not_supported");
            return;
        }

        firebase.messaging().onMessage((payload) => {
            // Customize notification here
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
                body: payload.notification.body,
                icon:
                    payload.notification.icon ??
                    "https://nyangapay.com/img/icons/android-chrome-192x192.png",
            };

            navigator.serviceWorker
                .getRegistration()
                .then((reg: ServiceWorkerRegistration | undefined) => {
                    if (!reg) {
                        captureSentryException(
                            new Error("no service worker registration")
                        );
                        return;
                    }

                    reg.showNotification(
                        notificationTitle,
                        notificationOptions
                    );
                });
        });
    }

    logout(): void {
        addAnalyticsEvent("user_is_logging_out");
        this.isLoggingOut = true;
        firebase
            .auth()
            .signOut()
            .then(() => {
                this.setNavRoute(null);
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    "Your have been logged out successfully"
                );

                if (this.$route.name !== ROUTE_NAMES.HOME) {
                    this.$router.push({ name: ROUTE_NAMES.HOME });
                }
            })
            .catch((error) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    error.message
                );
            })
            .finally(() => {
                this.isLoggingOut = false;
                this.setUser(null);
            });
    }
}
